let shadowRoot;

window.addEventListener('heyflow-init', (event) => {
    shadowRoot = 
        (document.querySelector('heyflow-wrapper') &&
            document.querySelector('heyflow-wrapper').shadowRoot) ||
        document;
        
        console.log(shadowRoot);
});


window.addEventListener('heyflow-change', (event) => {
    console.log(event.detail.screenName);

    if (event.detail.screenName === "contact-information") {
        
        // Get the container holding the radio buttons
        const radioContainer = shadowRoot.querySelector("#multiple-choice-block-id-5eaebaf0");

        // Get the selected radio button element
        const selectedRadio = radioContainer.querySelector('input[type="radio"]:checked');

        var selectedValue;

        // If a radio button is selected, get its value
        if (selectedRadio) {
        selectedValue = selectedRadio.dataset.answer;
        console.log("Selected value:", selectedValue);
        // You can use the selectedValue variable for further processing
        } else {
        console.log("No option selected");
        }

        // var datePickerContainer = shadowRoot.querySelector("#date-picker-id-290b4dbf");
        var datePickerContainer = shadowRoot.querySelector("#id-2b7a85fc-container");
        
        var timePickerContainer = shadowRoot.querySelector("#select-block-id-33c1465f");


        console.log(datePickerContainer.style.display);
        console.log(timePickerContainer.style.display);

        if(selectedValue == "Scheduled Call"){

            console.log("Schedule a Call");

            if (datePickerContainer) {
                datePickerContainer.style.display = "block";
            }
            
            if (timePickerContainer) {
                timePickerContainer.style.display = "block";
            }
        }
        else{

            console.log("Do Not Schedule a Call");

            if (datePickerContainer) {
                datePickerContainer.style.display = "none";
            }
            
            if (timePickerContainer) {
                timePickerContainer.style.display = "none";
            }
        }
            
    }

});
